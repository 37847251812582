import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { disableLeaveConfirmation, enableLeaveConfirmation } from '../../utils'
import { validMinDateAsDayjs } from '../../constants'
import { bobaService } from '../services'
// eslint-disable-next-line import/no-cycle
import { AppThunkConfig } from '../../store'
import { ApiRequest, AppError } from '../../AppTypes'
import {
  ApiError,
  AuthorizationError,
  OutOfServiceError,
} from '../services/errors'
import { ErrorCodeEnum } from '../services/api'
import {
  authenticationErrorAppError,
  internalServerErrorAppError,
  outOfServiceAppErr,
} from './common'

const service = bobaService()

type State = {
  isValidDate: boolean
  success: boolean
  salesDateAsNumber: number
  salesDateId?: string
} & ApiRequest

export const calculateSalesDate = (): number => {
  const salesDate = new Date(Date.now())
  const currentHour = salesDate.getHours()
  if (currentHour < 2 || (currentHour === 2 && salesDate.getMinutes() <= 30)) {
    salesDate.setDate(salesDate.getDate() - 1)
  }
  return salesDate.getTime()
}

const initialState: State = {
  success: false,
  isValidDate: true,
  salesDateAsNumber: calculateSalesDate(),
  apiStatus: 'Initial',
  error: undefined,
}

const handleError = (err: Error): AppError => {
  if (err instanceof ApiError) {
    switch (err.errorCode) {
      case (ErrorCodeEnum.InvalidInput, ErrorCodeEnum.InvalidSalesDate):
        return {
          needReturnTop: false,
          description: '不正な売上日です。',
          title: '売上日登録エラー',
        }
      case ErrorCodeEnum.SalesdateAlreadyExists:
        return {
          needReturnTop: false,
          description: `その売上日はすでに登録済みです。
別の売上日を登録してください。`,
          title: '売上日登録エラー',
        }
      case ErrorCodeEnum.InvalidToken:
        return {
          needReturnTop: true,
          description:
            '所定の時間が経過したので、無効になりました。初めからお試しください。',
          title: '',
        }
      case ErrorCodeEnum.InternalServerError:
        return internalServerErrorAppError
      default:
        return {
          needReturnTop: false,
          description:
            '売上日を登録中にエラーが発生しました。時間をおいて再度お試しください。',
          title: '売上日登録エラー',
        }
    }
  } else if (err instanceof OutOfServiceError) {
    return outOfServiceAppErr
  } else if (err instanceof AuthorizationError) {
    return authenticationErrorAppError
  }
  return internalServerErrorAppError
}

export const salesDateCreate = createAsyncThunk<number, Date, AppThunkConfig>(
  'salesDate/create',
  async (arg, { getState, rejectWithValue }) => {
    try {
      await service.postSalesDate(
        { date: dayjs(arg).format('YYYY-MM-DD') },
        getState()
      )
      return arg.getTime()
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

export const postSalesDateRegister = createAsyncThunk<
  void,
  string,
  AppThunkConfig
>('salesDate/register', async (arg, { getState, rejectWithValue }) => {
  try {
    return await service.postAppSalesdateSalesDateIdRegisters(arg, getState())
  } catch (err) {
    return rejectWithValue(handleError(err))
  }
})

const salesDateSlice = createSlice({
  name: 'salesDate',
  initialState,
  reducers: {
    clearSalesDate: (state): State => {
      return {
        ...state,
        success: false,
        isValidDate: true,
        apiStatus: 'Initial',
        error: undefined,
      }
    },
    updateSalesDate: (
      state,
      { payload: dateAsNumber }: PayloadAction<number>
    ): State => {
      if (Number.isNaN(dateAsNumber)) {
        return {
          ...state,
          salesDateAsNumber: NaN,
          isValidDate: false,
        }
      }
      const d = dayjs(dateAsNumber)
      const now = dayjs(calculateSalesDate())
      return {
        ...state,
        salesDateAsNumber: dateAsNumber,
        isValidDate:
          d.isSame(now, 'day') ||
          (d.isBefore(now, 'day') && d.isAfter(validMinDateAsDayjs, 'day')),
      }
    },
    setSalesDateId: (
      state,
      { payload: salesDateId }: PayloadAction<string>
    ): State => {
      return {
        ...state,
        salesDateId,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(salesDateCreate.pending, (state) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Progress',
      }
    })
    builder.addCase(salesDateCreate.fulfilled, (state, { payload }) => {
      disableLeaveConfirmation()
      return {
        ...state,
        salesDateAsNumber: payload,
        apiStatus: 'Success',
      }
    })
    builder.addCase(salesDateCreate.rejected, (state, action) => {
      disableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Failure',
        error: action.payload,
      }
    })
    builder.addCase(postSalesDateRegister.pending, (state) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Progress',
      }
    })
    builder.addCase(postSalesDateRegister.fulfilled, (state) => {
      disableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Success',
      }
    })
    builder.addCase(postSalesDateRegister.rejected, (state, action) => {
      disableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Failure',
        error: action.payload,
      }
    })
  },
})

export const {
  clearSalesDate,
  updateSalesDate,
  setSalesDateId,
} = salesDateSlice.actions

export default salesDateSlice
