import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import styles from './style.module.scss'
import AppHeader from '../../components/organisms/AppHeader'
import { RootState } from '../../store'
import Typography from '../../components/atoms/Typography'
import {
  titleText,
  resentTitleText,
  nextText,
  prevText,
  loadingModalText,
} from './messages'
import IconDateInputForm from '../../components/molecules/IconDateInputForm'
import Button from '../../components/atoms/Button'
import LoadingModal from '../../components/organisms/LoadingModal'
import {
  salesDateCreate,
  updateSalesDate,
  clearSalesDate,
  postSalesDateRegister,
} from '../../features/network/salesDateSlice'
import {
  preCameraPath,
  reportCommandPath,
  topWithClearDataPath,
} from '../../routes/paths'
import useTenant from '../hooks/useTenant'
import useExpire from '../hooks/useExpire'
import ErrorModal from '../../components/organisms/ErrorModal'
import { AppError } from '../../AppTypes'
import Div100vh from '../../components/atoms/Div100vh'
import useMode, { useResentMode, useTrainingMode } from '../hooks/useMode'
import { clearMode } from '../../features/mode/modeSlice'
import StepperHeader from '../../components/organisms/StepperHeader'
import useAppHistory from '../../utils/useAppHistory'

type Props = {}
const SalesDate: React.StyledFC<Props> = () => {
  const dispatch = useDispatch()
  const tenant = useTenant()
  const isTraining = useTrainingMode()
  const isResent = useResentMode()
  const mode = useMode()
  const {
    apiStatus,
    error,
    isValidDate,
    salesDateAsNumber,
    salesDateId,
  } = useSelector((state: RootState) => state.salesDate)
  const { tenantDetail } = useSelector((state: RootState) => state.auth)
  const history = useAppHistory()

  useExpire()
  useEffect((): void => {
    if (apiStatus === 'Success') {
      history.push(preCameraPath)
    }
  }, [apiStatus, history])
  const handleChange = (d: Date): void => {
    dispatch(updateSalesDate(d.getTime()))
  }
  const date = new Date(salesDateAsNumber)

  const handleClickPrev = (): void => {
    dispatch(clearMode())
    history.push(reportCommandPath)
  }

  const tenantContractStartDate = tenantDetail?.contractStartDate
    ? tenantDetail.contractStartDate
    : undefined

  const checkDate = (
    selectingDate: Date,
    contractStartDate?: string
  ): boolean => {
    if (!contractStartDate) return true
    return !dayjs(selectingDate).isBefore(dayjs(contractStartDate))
  }

  const handleClickNext = (): void => {
    if (isTraining) {
      history.push(preCameraPath)
      return
    }

    if (isResent && salesDateId) {
      dispatch(postSalesDateRegister(salesDateId))
      return
    }

    dispatch(salesDateCreate(date))
  }
  const errorModalButtonClick = (err: AppError): void => {
    if (err.needReturnTop) {
      history.push(topWithClearDataPath)
      return
    }
    dispatch(clearSalesDate())
  }
  return (
    <Div100vh className={styles.container} mode={mode}>
      <StepperHeader current={0} />
      <AppHeader tenantName={tenant.tenantName} storeName={tenant.storeName} />
      <div className={styles.wrapper}>
        <Typography className={styles.title} color="primary">
          {isResent ? resentTitleText : titleText}
        </Typography>
        {isResent ? (
          <Typography className={styles.date} align="center">
            {dayjs(date).format('YYYY/MM/DD')}
          </Typography>
        ) : (
          <IconDateInputForm
            name="salesDate"
            error={error && error.description}
            defaultValue={date}
            onChange={handleChange}
            className={styles.input}
          />
        )}
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.button}
            onClick={handleClickNext}
            disabled={
              (!isResent && !isValidDate) ||
              !checkDate(date, tenantContractStartDate)
            }
          >
            {nextText}
          </Button>
          <Button
            className={styles.button}
            onClick={handleClickPrev}
            color="white"
          >
            {prevText}
          </Button>
        </div>
      </div>
      <LoadingModal isOpen={apiStatus === 'Progress'} text={loadingModalText} />
      {error && (
        <ErrorModal
          isOpen
          title={error.title}
          description={error.description}
          buttonTitle="OK"
          onClick={(): void => errorModalButtonClick(error)}
        />
      )}
    </Div100vh>
  )
}

export default SalesDate
