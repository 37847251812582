import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { disableLeaveConfirmation, enableLeaveConfirmation } from '../../utils'
import { ApiRequest, AppError } from '../../AppTypes'
import { bobaService } from '../services'
import { ErrorCodeEnum, Image } from '../services/api'
// eslint-disable-next-line import/no-cycle
import { AppThunkConfig } from '../../store'
import {
  ApiError,
  AuthorizationError,
  OutOfServiceError,
} from '../services/errors'
import { authenticationErrorAppError, outOfServiceAppErr } from './common'

const service = bobaService()

type State = {
  progress: number
} & ApiRequest

const initialState: State = {
  progress: 0,
  apiStatus: 'Initial',
  error: undefined,
}

const uploadProgress = createAction(
  'image/create/pending',
  (progress: number) => {
    return {
      payload: progress,
    }
  }
)

type Progress = { progress: number }

const handleError = (err: Error): AppError => {
  if (err instanceof ApiError) {
    switch (err.errorCode) {
      case ErrorCodeEnum.InvalidImage:
        return {
          needReturnTop: false,
          description:
            '不正な画像データがありました、表示されてない画像がありましたら再撮影をお願いいたします。',
          title: '画像送信エラー',
        }
      case ErrorCodeEnum.NotFound:
        return {
          needReturnTop: true,
          description:
            '複数端末での利用が確認されましたので、当端末での売上報告は無効となりました。',
          title: '売上報告無効',
        }
      case ErrorCodeEnum.InvalidSalesDate:
        return {
          needReturnTop: true,
          description:
            '管理側で報告値が確定されたため、画像再送信が無効になりました。',
          title: '',
        }
      case ErrorCodeEnum.InvalidToken:
        return {
          needReturnTop: true,
          description:
            '所定の時間が経過したので、無効になりました。初めからお試しください。',
          title: '',
        }
      default:
        return {
          needReturnTop: false,
          description:
            '画像データ送信中にエラーが発生しました。再度お試しください。',
          title: '画像送信エラー',
        }
    }
  } else if (err instanceof OutOfServiceError) {
    return outOfServiceAppErr
  } else if (err instanceof AuthorizationError) {
    return authenticationErrorAppError
  }
  // FIXME: 2023/8/7に暫定対応。タイムアウト以外もあるので、後日複数のエラーに分離する。
  return {
    needReturnTop: false,
    description: '通信環境の良い場所で再度お試しください。',
    title: 'タイムアウトエラー',
  }
}

export const uploadImage = createAsyncThunk<Progress, Image[], AppThunkConfig>(
  'image/create',
  async (args: Image[], { getState, dispatch, rejectWithValue }) => {
    try {
      await service.postImages(
        {
          images: args.map((i) => ({
            index: i.index,
            data: i.data.replace('data:image/jpeg;base64,', ''),
          })),
        },
        getState(),
        (progress) => {
          dispatch(uploadProgress(progress))
        }
      )
      return { progress: 100 }
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

export const uploadResentImage = createAsyncThunk<
  Progress,
  Image[],
  AppThunkConfig
>(
  'image/resent',
  async (args: Image[], { getState, dispatch, rejectWithValue }) => {
    try {
      await service.postResentImages(
        {
          images: args.map((i) => ({
            index: i.index,
            data: i.data.replace('data:image/jpeg;base64,', ''),
          })),
        },
        getState(),
        (progress) => {
          dispatch(uploadProgress(progress))
        }
      )
      return { progress: 100 }
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

const uploadImageSlice = createSlice({
  name: 'uploadImage',
  initialState,
  reducers: {
    clearUploadImage: (): State => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadImage.pending, (state, { payload }) => {
      enableLeaveConfirmation()
      return {
        ...state,
        progress: payload || 0,
        apiStatus: 'Progress',
      }
    })
    builder.addCase(uploadImage.fulfilled, (state) => {
      disableLeaveConfirmation()
      return {
        ...state,
        progress: 100,
        apiStatus: 'Success',
      }
    })
    builder.addCase(uploadImage.rejected, (state, action) => {
      disableLeaveConfirmation()
      return {
        ...state,
        progress: 0,
        apiStatus: 'Failure',
        error: action.payload,
      }
    })
    builder.addCase(uploadResentImage.pending, (state, { payload }) => {
      enableLeaveConfirmation()
      return {
        ...state,
        progress: payload || 0,
        apiStatus: 'Progress',
      }
    })
    builder.addCase(uploadResentImage.fulfilled, (state) => {
      disableLeaveConfirmation()
      return {
        ...state,
        progress: 100,
        apiStatus: 'Success',
      }
    })
    builder.addCase(uploadResentImage.rejected, (state, action) => {
      disableLeaveConfirmation()
      return {
        ...state,
        progress: 0,
        apiStatus: 'Failure',
        error: action.payload,
      }
    })
  },
})

export const { clearUploadImage } = uploadImageSlice.actions

export default uploadImageSlice
