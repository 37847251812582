import React from 'react'
import clsx from 'clsx'
import Icon from '../../atoms/Icon'
import styles from './style.module.scss'
import HorizontalTenantStoreName from '../../molecules/HorizontalTenantStoreName'
import Space from '../../atoms/Space'

type AppHeaderProps = {
  tenantName: string
  storeName: string
}

type Props = AppHeaderProps & React.StyledProps
const AppHeader: React.StyledFC<Props> = ({
  tenantName,
  storeName,
  style,
  className,
}: Props) => {
  return (
    <div className={clsx(className, styles.container)} style={style}>
      <Icon icon="shop" className={styles.icon} />
      <Space orientation="horizontal" size="9px" />
      <HorizontalTenantStoreName
        tenantName={`${tenantName} 様`}
        storeName={storeName}
        className={styles.text}
      />
    </div>
  )
}

export default AppHeader
