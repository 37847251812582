import React from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'
import Typography from '../../atoms/Typography'
import Space from '../../atoms/Space'

type HorizontalTenantStoreNameProps = {
  storeName: string
  tenantName: string
}

type Props = HorizontalTenantStoreNameProps & React.StyledProps
const HorizontalTenantStoreName: React.StyledFC<Props> = ({
  storeName,
  tenantName,
  style,
  className,
}: Props) => {
  return (
    <div
      className={clsx(className, styles.container)}
      style={style}
      data-testid="horizontal-tenant-shop"
    >
      <Typography variant="span" color="primary" className={styles.tenantName}>
        {tenantName}
      </Typography>
      <Space orientation="horizontal" size="8px" />
      <Typography variant="span" color="primary" className={styles.storeName}>
        {storeName}
      </Typography>
    </div>
  )
}

export default HorizontalTenantStoreName
