import React from 'react'
import clsx from 'clsx'
import { ReactComponent as AlertIcon } from './assets/alert.svg'
import { ReactComponent as SmileIcon } from './assets/smile.svg'
import { ReactComponent as LightIcon } from './assets/light.svg'
import { ReactComponent as CloseIcon } from './assets/close.svg'
import { ReactComponent as ShopIcon } from './assets/shop.svg'
import { ReactComponent as EditIcon } from './assets/edit.svg'
import { ReactComponent as NextIcon } from './assets/next.svg'
import { ReactComponent as NGIcon } from './assets/ng.svg'
import { ReactComponent as PlusIcon } from './assets/plus.svg'
import { ReactComponent as ChevronRightIcon } from './assets/chevronRight.svg'
import { ReactComponent as HorizontalIcon } from './assets/horizontal.svg'
import { ReactComponent as VerticalIcon } from './assets/vertical.svg'
import { ReactComponent as MemoIcon } from './assets/memo.svg'
import { ReactComponent as ReceiptIcon } from './assets/receipt.svg'
import { ReactComponent as LeftArrowIcon } from './assets/leftArrow.svg'
import { ReactComponent as RightArrowIcon } from './assets/rightArrow.svg'
import { ReactComponent as RightPointing } from './assets/rightPointing.svg'
import { ReactComponent as PartyPopper } from './assets/partyPopper.svg'
import { ReactComponent as SubmitIcon } from './assets/submit.svg'

import styles from './style.module.scss'

// SVGファイルの色をつけているpathなどのfillは消してクラスをつける必要があります
// fill -> class="fill"
// fill-stroke -> class="fill-stroke"

export type IconNames =
  | 'alert'
  | 'smile'
  | 'light'
  | 'close'
  | 'shop'
  | 'edit'
  | 'next'
  | 'ng'
  | 'plus'
  | 'chevronRight'
  | 'horizontal'
  | 'vertical'
  | 'memo'
  | 'receipt'
  | 'leftArrow'
  | 'rightArrow'
  | 'rightPointing'
  | 'partyPopper'
  | 'submit'

const icons = {
  alert: AlertIcon,
  smile: SmileIcon,
  light: LightIcon,
  close: CloseIcon,
  shop: ShopIcon,
  edit: EditIcon,
  next: NextIcon,
  ng: NGIcon,
  plus: PlusIcon,
  chevronRight: ChevronRightIcon,
  horizontal: HorizontalIcon,
  vertical: VerticalIcon,
  memo: MemoIcon,
  receipt: ReceiptIcon,
  leftArrow: LeftArrowIcon,
  rightArrow: RightArrowIcon,
  rightPointing: RightPointing,
  partyPopper: PartyPopper,
  submit: SubmitIcon,
}
type IconProps = {
  icon: IconNames
  color?: AppColor
  onClick?: () => void
}
type Props = IconProps & React.StyledProps

const Icon: React.StyledFC<IconProps> = ({
  onClick,
  icon,
  color = 'primary',
  style,
  className,
}: Props) => {
  const Component = icons[icon]
  if (onClick) {
    return (
      <div onClick={onClick} className={clsx(className, styles.container)}>
        <Component
          data-testid={`icon-${icon}`}
          className={clsx(styles.icon, styles[icon], styles[color])}
          style={style}
        />
      </div>
    )
  }
  return (
    <Component
      data-testid={`icon-${icon}`}
      className={clsx(className, styles.icon, styles[icon], styles[color])}
      style={style}
    />
  )
}

export default Icon
