export const titleTop = 'トップ'
export const titleQRCodeReader = 'QRコード読み取り'
export const titleSalesDate = `売上日登録`
export const titleReportCommand = `モード選択`
export const titleTitlePre = `レシート撮影前確認`
export const titleCameraGuide = `レシート撮影前ガイド`
export const titleCapture = `レシート撮影`
export const titleConfirmReceiptAll = `レシート全体確認`
export const titleReceiptUploadCompleted = `レシートアップロード完了`
export const titleSalesDataInput = `売上データ入力`
export const titleSalesDataInputConfirm = `売上データ入力確認`
export const titleSubmitted = `完了画面`
export const titleRegisterPreCamera = `複数レジ撮影前確認`
export const titleRegisterConfirmation = `複数レジ確認画面`
export const titleMessageConfirmation = `メッセージ確認画面`
export const titleMessageInput = `メッセージ入力画面`
export const titleMessageInputConfirm = `メッセージ内容入力画面`
